<template>
    <div class="partiBox">
        <img v-if="resData.titleImage" :src="resData.titleImage" style="display: none;">
        <div class="XQBox">
            <h3>{{resData.newsTitle}}</h3>
            <p>发布时间：{{resData.infoStartTime}}</p>
            <div v-html="resData.newsContent"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'mobile_shgk_bzryDetail',
        data() {
            return {
                resData: {}
            }
        },
		created() {
			if (!/Android|webOS| iPhone localStorage| iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i
			    .test(navigator.userAgent)) {
						// console.log(123)
			        this.$router.push({
			            path: "/shgk/bzry/detail",
						query:{
							id:this.$route.query.id
						}
			        });
			}
		},
        mounted() {
            this.getInfoData()
        },
        methods: {
            wxShareInfo(title, content, imgUrl) {
                const {wxSdkUtil} = require("@/util/wechat/wxSdkUtil");
                let obj = {};
                obj.title = title;
                obj.content = content;
                let defaultSrc = 'http://' + window.location.host + '/upload/default/newstitle.jpg';
                if (imgUrl.indexOf('http') < 0) {
                    imgUrl = 'http://' + window.location.host + imgUrl;
                }
                obj.img = imgUrl ? imgUrl : defaultSrc;
                wxSdkUtil.setShareInfo(obj.img, obj.title, obj.content, null)
            },
            getInfoData() {
                if (this.$route.path == '/mobile/shgk/bzry/detail') {
                    const id = this.$route.query.id
                    if (typeof (id) == 'undefined' || id == '') {
                        return;
                    }
                    this.$http.shgkShbzryDetail({id: id}).then(res => {
                        if (res.code == 200) {
                            this.resData = res.data
                            this.wxShareInfo(res.data.newsTitle, res.data.shareContent, res.data.titleImage);
                        }
                    })
                }
            }
        },
        watch: {
            $route: 'getInfoData'
        }
    }
</script>
<style type="text/css">
    .partiBox {
        -webkit-overflow-scrolling: touch;
        overflow: auto;
    }

    .XQBox > h3 {
        width: 90.1vw;
        margin: 5.9vw 0 4.3vw 4.3vw;
        color: #000000;
        font-size: 5.3vw;
    }

    .XQBox > p {
        margin: 0vw 0 7.7vw 4.3vw;
        color: #999999;
        font-size: 3.7vw;
    }

    .XQBox > div {
        padding: 0 4.3vw;
        margin-bottom: 16vw;
        color: #333333;
        font-size: 4vw;
    }

    .XQBox > div img {
        width: 92vw;
        height: auto;
        margin: 5vw auto;
    }

    .XQBox > div p {
        text-align: justify;
        font-size: 4vw;
    }
</style>
